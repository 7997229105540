import { Injectable } from '@angular/core'

import { iif, of } from 'rxjs'
import { LoginService } from '@core/services/login.service'
import { AuthChangeEvent, Session, SupabaseClient } from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'

@Injectable({
    providedIn: 'root',
})
export class SupabaseAuthService {
    supabase: SupabaseClient
    constructor(
        private loginService: LoginService,
        private supabaseService: SupabaseService,
    ) {
        this.supabase = this.supabaseService.getClient()
    }

    get session() {
        return this.supabase.auth.getSession()
    }

    authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
        return this.supabase.auth.onAuthStateChange(callback)
    }

    signIn(email: string) {
        return this.supabase.auth.signInWithOtp({ email, options: { shouldCreateUser: false } })
    }

    signOut() {
        return this.supabase.auth.signOut()
    }

    menu() {
        return iif(() => !!this.session, this.loginService.menu(), of([]))
    }
}
